import React from 'react';
import MenuLoggedIn from "../components/menu/MenuLoggedIn";
import {Alert} from "../components/alert/Alert";


const LoggedInLayout:React.FC = props => {

    return <div className="loggedInLayout container">
        <MenuLoggedIn />
        <Alert />
        <div className="main-content mt-5">
            {props.children}
            <div className="d-flex flex-column align-items-center">
                <img className="w-50 m-2" src={process.env.PUBLIC_URL + "/ivace.jpg"} alt="ivace"/>
            </div>
        </div>

    </div>;
}

export default LoggedInLayout;
