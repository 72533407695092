import React from 'react';
import {Alert} from "../components/alert/Alert";

const GuestLayout:React.FC = props =>
<div className="guestLayout container">
    <Alert/>
    {props.children}
    <div className="d-flex flex-column align-items-center">
        <img className="w-50 m-2"  src={process.env.PUBLIC_URL + "/ivace.jpg"} />
    </div>
</div>;

export default GuestLayout;
